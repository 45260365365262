<script>
import ResourceFrame from "@/screens/resourcesIndex/components/ResourceFrame.vue";
import { SuawInputGroup, SuawHeading } from "@/components";
export default {
  name: "HostingYourOnlineEvent",
  components: { ResourceFrame, SuawInputGroup, SuawHeading }
};
</script>

<template>
  <ResourceFrame resource-side-nav-type="organizer">
    <SuawInputGroup direction="column" group-gap="quadruple" prevent-flex-grow>
      <h1 class="suaw-organizer-resource-heading">Hosting Your <b>Online Event</b></h1>
      <SuawHeading
        class="suaw-organizer-resource-intro"
        level="3"
        content="If you'll be hosting online, please take some time to read through this guide. We've been hosting online events since 2020 and we've learned a few things we'd like to pass along."
      />

      <h2 class="suaw-organizer-resource-subtitle">
        Video Conferencing Software
      </h2>
      <p class="suaw-paragraph">
        To host an online event, you'll need to have access to video conferencing software that will allow you to host a meeting for over an hour. There are both free and paid software options, but in many cases, free accounts will not allow meetings over an hour, so please check the specifications of your preferred software.
      </p>
      <p class="suaw-paragraph">Popular options for hosting Shut Up & Write! include:</p>
      <ul class="suaw-organizer-resource-list">
        <li class="suaw-organizer-resource-list__item">
          <p class="suaw-paragraph">Zoom</p>
        </li>
        <li class="suaw-organizer-resource-list__item">
          <p class="suaw-paragraph">Whereby</p>
        </li>
        <li class="suaw-organizer-resource-list__item">
          <p class="suaw-paragraph">Google Meet</p>
        </li>
        <li class="suaw-organizer-resource-list__item">
          <p class="suaw-paragraph">Skype</p>
        </li>
        <li class="suaw-organizer-resource-list__item">
          <p class="suaw-paragraph">Jitsi</p>
        </li>
      </ul>
      <p class="suaw-paragraph">
        Once you select your software, do a test run and make sure you understand how to set up an event, and how to use basic functions like admitting and muting participants.
      </p>

      <h2 class="suaw-organizer-resource-subtitle">
        Timing
      </h2>
      <p class="suaw-paragraph">You'll also need to choose a time for your event. A few recommendations:</p>
      <ul class="suaw-organizer-resource-list">
        <li class="suaw-organizer-resource-list__item">
          <p class="suaw-paragraph">Ensure the time you'd like to host isn't the same as an existing event in your chapter. You can check this by looking at event listings on your local Shut Up & Write! chapter on Meetup or <a href='http://shutupwrite.com' target='_blank'>shutupwrite.com</a>.</p>
        </li>
        <li class="suaw-organizer-resource-list__item">
          <p class="suaw-paragraph">Ensure you have no other weekly or monthly commitments during that time.</p>
        </li>
        <li class="suaw-organizer-resource-list__item">
          <p class="suaw-paragraph">Saturday morning sessions at 10am or 11am are well-attended across most of our chapters, so this is a solid choice unless there's an existing event already at this time. Weekdays at 6pm also work well to allow folks working a 9-5 job to attend your event after work.</p>
        </li>
      </ul>

      <h2 class="suaw-organizer-resource-subtitle">
        Format
      </h2>
      <p class="suaw-paragraph">Standard online events last 90 minutes. If you'd like to host for a longer period of time, this format can be expanded. Here's an example of a classic Shut Up & Write! event schedule:</p>
      
      <div class="suaw-organizer-resource-schedule">
        <div class="suaw-organizer-resource-schedule__item">
          <span class="suaw-organizer-resource-schedule__emoji">👋</span>
          <span class="suaw-organizer-resource-schedule__text">15 mins: Introductions</span>
        </div>
        <div class="suaw-organizer-resource-schedule__item">
          <span class="suaw-organizer-resource-schedule__emoji">⏰</span>
          <span class="suaw-organizer-resource-schedule__text">60 mins: Shut Up & Write!</span>
        </div>
        <div class="suaw-organizer-resource-schedule__item">
          <span class="suaw-organizer-resource-schedule__emoji">✅</span>
          <span class="suaw-organizer-resource-schedule__text">15 mins: Conclusion</span>
        </div>
      </div>

      <p class="suaw-paragraph">If your writing period is longer than 60 minutes, we recommend scheduling breaks into your event. Keep in mind that focusing for long periods of time in front of a computer screen can be challenging.</p>
      
      <SuawHeading level="4" content="Pomodoro Technique" />
      <p class="suaw-paragraph">We know many of our writers are big fans of <a href='https://en.wikipedia.org/wiki/Pomodoro_Technique' target='_blank'>this method</a>, which entails working for a set interval (usually 25 minutes), taking a 5 minute break, and repeating. While this is not the same as the <router-link :to="{ name: 'Method' }">Shut Up & Write! Method</router-link>, we recognize that this can be a very effective technique, so if you are a Pomodoro fan, feel free to utilize it in your events.</p>

      <h2 class="suaw-organizer-resource-subtitle">
        Getting Ready
      </h2>
      <p class="suaw-paragraph">We've got a <router-link :to="{ name: 'OnlineEventChecklist' }">handy checklist</router-link> that you can print to get ready for an online event, so be sure to check that out. Before your event, make sure you do the following:</p>
      <ul class="suaw-organizer-resource-list">
        <li class="suaw-organizer-resource-list__item">
          <p class="suaw-paragraph">Test out your video software.</p>
        </li>
        <li class="suaw-organizer-resource-list__item">
          <p class="suaw-paragraph">Promote your event on social media using your finalized event link and social flyer.</p>
        </li>
        <li class="suaw-organizer-resource-list__item">
          <p class="suaw-paragraph">The day before your event, Meetup Message the members on your RSVP list, and let them know you're excited to write with them soon!</p>
        </li>
      </ul>

      <h2 class="suaw-organizer-resource-subtitle">
        The Day Of
      </h2>
      <ul class="suaw-organizer-resource-list">
        <li class="suaw-organizer-resource-list__item">
          <p class="suaw-paragraph">Log into your virtual room 5 minutes before your event's start time</p>
        </li>
        <li class="suaw-organizer-resource-list__item">
          <p class="suaw-paragraph">Turn on security settings (if using Zoom, this is accessible via the Security Button at the bottom of the screen)</p>
        </li>
        <li class="suaw-organizer-resource-list__item">
          <p class="suaw-paragraph">Turn off: &quot;Allow participants to share screen&quot;</p>
        </li>
        <li class="suaw-organizer-resource-list__item">
          <p class="suaw-paragraph">Turn off: &quot;Allow participants to rename themselves&quot;</p>
        </li>
        <li class="suaw-organizer-resource-list__item">
          <p class="suaw-paragraph">As people arrive, let them in from the waiting room and welcome them to your Shut Up & Write!</p>
        </li>
        <li class="suaw-organizer-resource-list__item">
          <p class="suaw-paragraph">Introduce yourself: Ask them how their day is going, where they are calling in from, etc.</p>
        </li>
        <li class="suaw-organizer-resource-list__item">
          <p class="suaw-paragraph">Begin the event once everyone who RSVPs has arrived, or a few minutes has passed</p>
        </li>
        <li class="suaw-organizer-resource-list__item">
          <p class="suaw-paragraph">Start by officially welcoming the group in and then starting the check-in with something like, &quot;Let's go around and say, in a couple of sentences, what you are working on today.&quot;</p>
        </li>
        <li class="suaw-organizer-resource-list__item">
          <p class="suaw-paragraph">Go around the room, calling on people by name to make sure people are clear about who should speak next.</p>
        </li>
        <li class="suaw-organizer-resource-list__item">
          <p class="suaw-paragraph">Once everyone has stated what they're writing, let the group know you are about to set the timer and that it's time to Shut Up & Write!</p>
        </li>
        <li class="suaw-organizer-resource-list__item">
          <p class="suaw-paragraph">Remember to set your timer!</p>
        </li>
        <li class="suaw-organizer-resource-list__item">
          <p class="suaw-paragraph">During writing, mute your participants and turn off any option that would allow them to change this setting. This will reduce background noise if people arrive late and mitigate any unintentional un-muting.</p>
        </li>
        <li class="suaw-organizer-resource-list__item">
          <p class="suaw-paragraph">After Writing: Ensure people can unmute themselves (if using Zoom, check the Security button) Unmute yourself and welcome everyone back. (Try welcoming your group back softly because an enthusiastic &quot;welcome back&quot; can make some writers jump!)</p>
        </li>
        <li class="suaw-organizer-resource-list__item">
          <p class="suaw-paragraph">Lead a round of check-ins at the end. Feel free to stay afterward to connect with writers and foster additional conversations. Building a community involves folks getting to know each other as people, not just silent writing.</p>
        </li>
        <li class="suaw-organizer-resource-list__item">
          <p class="suaw-paragraph">Encourage attendees to help spread the word about your event.Thank the group for showing up, invite them to come back again, and paste the link to your next event in the chat.</p>
        </li>
      </ul>

      <h2 class="suaw-organizer-resource-subtitle">
        Tricky Situations
      </h2>
      <ul class="suaw-organizer-resource-list">
        <li class="suaw-organizer-resource-list__item">
          <p class="suaw-paragraph"><b>Latecomers:</b> Welcome them to the event using the chat feature. Ask them what they're working on and let them know how much writing time is left.</p>
        </li>
        <li class="suaw-organizer-resource-list__item">
          <p class="suaw-paragraph"><b>People unmuting during the writing time:</b> Remind them that it's time for quiet writing, but there will be time for socializing at the end.</p>
        </li>
        <li class="suaw-organizer-resource-list__item">
          <p class="suaw-paragraph"><b>Unwanted Guests:</b> Just in case, here's our recommended procedure. Remove the unwanted participant as quickly as possible using your video software. Once they're gone, send a message to the group in chat to let them know that you have removed the unwanted guest(s) and the event can now proceed as originally intended.</p>
        </li>
      </ul>

      <h2 class="suaw-organizer-resource-subtitle">
        Managing Your Event Listing
      </h2>
      <ul class="suaw-organizer-resource-list">
        <li class="suaw-organizer-resource-list__item">
          <p class="suaw-paragraph">To edit your in-person event listing on Meetup, [directions.] Once you make changes on Meetup, your changes will be available on the <a href='http://shutupwrite.com' target='_blank'>shutupwrite.com</a> platform.</p>
        </li>
        <li class="suaw-organizer-resource-list__item">
          <p class="suaw-paragraph">To message your RSVPed members, [directions.]</p>
        </li>
        <li class="suaw-organizer-resource-list__item">
          <p class="suaw-paragraph"><b>Retiring Your Event:</b> If you need to take a long-term break from hosting your Shut Up & Write! event, please ensure that your event is handed off to another organizer. If that's not possible, and you need to close down your event, contact <a href='mailto:questions@shutupwrite.com'>questions@shutupwrite.com</a> so we can remove your events from Meetup and shutupwrite.com. We understand that life happens, and we want our listings to be as current as possible so we don't have members attempting to attend events that no longer exist.</p>
        </li>
      </ul>
    </SuawInputGroup>
  </ResourceFrame>
</template>

<style lang="scss">
.suaw-paragraph {
  color: var(--sem-color-text-medium);
  line-height: var(--p-line-height);
  font-size: var(--p-font-size);
  font-weight: var(--p-font-weight);
}

.suaw-organizer-resource-intro {
  color: var(--sem-color-neutral-dark);
}
.suaw-organizer-resource-heading {
  margin: 0;
  padding: 0;
  color: #414142;
  font-family: Archer;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
}
.suaw-organizer-resource-subtitle {
  color: #414141;
  font-family: Archer;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
}
//list
.suaw-organizer-resource-list {
  list-style: disc;
  padding-left: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: var(--sem-color-text-medium);
  font-family: Roboto;

  &__item {
    padding-left: 8px;
  }
}
//schedule
.suaw-organizer-resource-schedule {
  background: var(--sem-color-surface-light);
  border-radius: 8px;
  padding: 24px;
  margin: 16px 0;

  &__item {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 16px;
    
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__emoji {
    font-size: 24px;
    min-width: 32px;
    text-align: center;
  }

  &__text {
    font-family: Roboto;
    color: var(--sem-color-text-medium);
    font-size: 16px;
  }
}
</style>
