<script>
import { SuawWriteWithUsSection } from "@/components";
export default {
  name: "WriteWithUs",
  components: { SuawWriteWithUsSection },
  methods: {
    onJoinClick() {
      this.$router.push({
        name: "SignUp"
      });
    }
  }
};
</script>

<template>
  <SuawWriteWithUsSection @click="onJoinClick" />
</template>
